<template>
    <div class="costAccounting">
        <div class="table-bg">
            <el-table
                :data="tableData6"
                stripe
                show-summary
                style="width: 100%;"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="姓名"
                >
                </el-table-column>
                <el-table-column
                    prop="amount1"
                    label="数值 1（元）"
                >
                </el-table-column>
                <el-table-column
                    prop="amount2"
                    label="数值 2（元）"
                >
                </el-table-column>
                <el-table-column
                    prop="amount3"
                    label="数值 3（元）"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    // 基础配比库——成本核算
    name: 'cost-accounting',
    components: {},
    props: {},
    data() {
        return {
            tableData6: [{
                id: '12987122',
                name: '王小虎',
                amount1: '234',
                amount2: '3.2',
                amount3: 10,
            }, {
                id: '12987123',
                name: '王小虎',
                amount1: '165',
                amount2: '4.43',
                amount3: 12,
            }, {
                id: '12987124',
                name: '王小虎',
                amount1: '324',
                amount2: '1.9',
                amount3: 9,
            }, {
                id: '12987125',
                name: '王小虎',
                amount1: '621',
                amount2: '2.2',
                amount3: 17,
            }, {
                id: '12987126',
                name: '王小虎',
                amount1: '539',
                amount2: '4.1',
                amount3: 15,
            }],
        };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.costAccounting
    height 100%
    .table-bg
        height 100%
        .el-table
            height 100%
</style>